import {
  action, makeObservable, observable,
} from 'mobx';

import { BookingSettingsModel } from '../../../modules/settings/components/BookingSettings';

class BookingStore {
  constructor() {
    makeObservable(this);
  }

  @observable accessor bookingSettings: BookingSettingsModel | null = null;
  @observable accessor loading = false;

  @action
  setBookingSettings = (data: BookingSettingsModel) => {
    this.bookingSettings = data;
  };

  @action
  setLoading = (value: boolean) => {
    this.loading = value;
  };
}

export const bookingStore = new BookingStore();
